<template>
	<div
	v-if="salon.filas && salon.columnas && salon.filas != '' && salon.columnas != ''">
		<div 
		:class="salon_class"
		class="salon">	
			<div 
			v-for="fila in Number(salon.filas)"
			class="fila">
				<div 
				v-for="columna in Number(salon.columnas)"
				class="columna">

					<div 
					:style="style(fila, columna)"
					:class="getClass(fila, columna)"
					@click="setMesa(fila, columna)"
					class="cuadrado">

						<div
						v-if="es_una_mesa(fila, columna)">


							<div
							v-if="modo == 'editar'">
								<i 
								@click="borrarMesa(fila, columna)"
								class="icon-cancel"></i>
								<input
								v-model="getMesa(fila, columna).nombre"
								placeholder="Nombre"></input>
								<button
								@click="girar(fila, columna)">
									<i class="icon-refresh"></i>
								</button>
							</div>

							<div
							v-else
							class="seleccionar-mesa"
							@click="clickMesa(fila, columna)">
								<span 
								class="nombre-mesa">
									{{ getMesa(fila, columna).nombre }}
								</span>
								<div	
								v-if="tiene_un_pedido(fila, columna)"
								class="pedido">
									<span
									class="d-none d-md-block">
										Pedido N° {{ getPedido(fila, columna).num }}
									</span>
									<span
									class="d-md-none">
										{{ getPedido(fila, columna).num }}
									</span>
									<span>
										<strong>
											{{ totalOrder(getPedido(fila, columna)) }}
										</strong>
									</span>
								</div>
							</div>

						</div>
					</div>

				</div>
			</div>
		</div>
		<div
		class="m-t-15"
		v-if="modo == 'editar'">
			<b-button
			variant="primary"
			@click="restart">
				Borrar mesas
			</b-button>
			<b-button
			variant="primary"
			class="m-l-15"
			@click="crearMesa">
				Crear mesa
			</b-button>
		</div>
	</div>
</template>
<script>
import display from '@/common-vue/mixins/display'
export default {
	props: {
		salon: Object,
		modo: String,
	},
	mixins: [display],
	created() {
		this.setCuadradosWidth()
	},
	computed: {
		mesas() {
			return this.salon.mesas
		},
		salon_class() {
			if (this.modo == 'editar') {
				return 'editar'
			}
			if (this.modo == 'seleccionar-mesa') {
				return 'seleccionar-mesa'
			}
		},
		pedidos() {
			return this.$store.state.order.models 
		},
	},
	data() {
		return {
			mesa_nueva: {
				nombre: '',
				girada: 0,
				cuadrado: '',
			},
		}
	},
	methods: {
		borrarMesa(fila, columna) {
			let index = this.salon.mesas.findIndex(mesa => {
				return mesa.cuadrado == this.getCuadrado(fila, columna)
			})
			this.salon.mesas.splice(index, 1)
			console.log('index '+index)
		},
		style(fila, columna) {
			if (this.modo == 'seleccionar-mesa' || this.modo == 'mapa-en-vivo') {
				let prop = this.getBorderColorProperty('order')
				if (this.tiene_un_pedido(fila, columna)) {
					let pedido = this.getPedido(fila, columna)
					let color = pedido[this.modelNameFromRelationKey(prop)].color
					return 'border: 6px solid '+color.code+' !important'
				} 
			}
			return ''
		},
		clickMesa(fila, columna) {
			if (this.modo == 'seleccionar-mesa') {
				this.seleccionarMesa(fila, columna)
			} else if (this.modo == 'mapa-en-vivo') {
				if (this.tiene_un_pedido(fila, columna)) {
					this.setModel(this.getPedido(fila, columna), 'order')
				}
			}
		},
		tiene_un_pedido(fila, columna) {
			let pedido = this.getPedido(fila, columna)
			return typeof pedido != 'undefined'
		},
		getPedido(fila, columna) {
			return this.pedidos.find(pedido => {
				return !pedido.is_paid && pedido.mesa && pedido.mesa.cuadrado == this.getCuadrado(fila, columna) && pedido.salon_id == this.salon.id
			})
		},
		setCuadradosWidth() {
			let salon = document.getElementsByClassName('salon')[0]
			if (typeof salon == 'undefined') {
				setTimeout(() => {
					this.setCuadradosWidth()		
					console.log('vuelvo a llamar a setCuadradosWidth')
				}, 500)
			} else {
				let cuadrados = document.getElementsByClassName('cuadrado')
				let width = salon.getBoundingClientRect().width / Number(this.salon.columnas)

				if (width > 100) {
					width = 100
				}

				for (var i = cuadrados.length - 1; i >= 0; i--) {
					cuadrados[i].style.width = width+'px'
					cuadrados[i].style.height = width+'px'
				}
			}
		},
		seleccionarMesa(fila, columna) {
			this.$emit('seleccionarMesa', this.getMesa(fila, columna))
			this.$bvModal.hide('select-mesa')
		},
		crearSalon() {
			this.filas = this.salon.filas
			this.columnas = this.salon.columnas
		},
		setMesa(fila, columna) {
			if (this.modo == 'editar' && !this.es_una_mesa(fila, columna)) {
				if (this.es_la_mesa_nueva(fila, columna)) {
					this.mesa_nueva.cuadrado = ''
				} else {
					this.mesa_nueva.cuadrado = this.getCuadrado(fila, columna)
				}
			}
		},
		crearMesa(fila, columna) {
			console.log('crearMesa')
			this.salon.mesas.push(this.mesa_nueva)
			this.mesa_nueva = {
				nombre: '',
				girada: 0,
				cuadrado: '',
			}
		},
		getClass(fila, columna) {
			if (this.es_la_mesa_nueva(fila, columna)) {
				return 'new-table'
			}
			if (this.es_una_mesa(fila, columna)) {
				if (this.getMesa(fila, columna).girada == 1) {
					return 'mesa girada'
				}
				return 'mesa'
			}
		},
		es_la_mesa_nueva(fila, columna) {
			return this.mesa_nueva.cuadrado == this.getCuadrado(fila, columna)
		},
		es_una_mesa(fila, columna) {
			let es_una_mesa = false 
			this.salon.mesas.forEach(mesa => {
				if (mesa.cuadrado == this.getCuadrado(fila, columna)) {
					es_una_mesa = true
				}
			})
			return es_una_mesa
		},
		getCuadrado(fila, columna) {
			return fila+'-'+columna
		},
		getMesa(fila, columna) {
			if (this.es_una_mesa(fila, columna)) {
				return this.salon.mesas.find(mesa => {
					return mesa.cuadrado == this.getCuadrado(fila, columna)
				})
			}
			return null
		},
		crearMesa() {
			this.salon.mesas.push({
				...this.mesa_nueva,
				nombre: '',
				girada: false,
			})
			this.mesa_nueva.cuadrado = ''
		},
		girar(fila, columna) {
			if (this.getMesa(fila, columna).girada) {
				this.getMesa(fila, columna).girada = 0
			} else {
				this.getMesa(fila, columna).girada = 1
			} 
		},
		restart() {
			this.mesa_nueva.cuadrado = ''
			this.mesas = []
		}
		
	}
}
</script>
<style lang="sass">
@import '@/sass/_custom'
.salon 
	display: flex 
	flex-direction: column
	justify-content: center 
	align-items: center
	margin-top: 30px
	// border: 1px solid #333
	.fila 
		display: flex 
		flex-direction: row

		.cuadrado
			border: 1px solid #333	

		.new-table
			background: red

		.mesa
			background: #C3C3C3
			border-radius: 5px
			position: relative

			@media screen and (max-width: 768px)
				padding: 0
			@media screen and (min-width: 768px)
				padding: 10px

			.icon-cancel
				position: absolute 
				top: 5px 
				right: 5px
				color: #000
				cursor: pointer
			
			div
				width: 100%
				height: 100%
				display: flex 
				flex-direction: column
				justify-content: center 
				align-items: center	

				input 
					width: 100%
					border: none 
					border-radius: 5px
					padding: 1px 5px
				button 
					border: none 

				.pedido 
					width: 100%
					@media screen and (max-width: 768px)
						font-size: 10px
					@media screen and (min-width: 768px)
						font-size: 13px
			
			.seleccionar-mesa
				display: flex 
				flex-direction: column 
				justify-content: center 
				align-items: center
				cursor: pointer
				.nombre-mesa
					font-weight: bold
					@media screen and (max-width: 768px)
						font-size: 10px 
					@media screen and (min-width: 768px)
						font-size: 14px 



		.girada	
			transform: rotate(45deg)

			& > div 
				transform: rotate(-45deg) 
				// transform: rotate(-22deg) 
				// margin-top: 5px
				// margin-left: 5px

// .editar
// 	.cuadrado
// 		border: 1px solid #333	
</style>